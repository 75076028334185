import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Indicators from "highcharts/indicators/indicators-all";
import DragPanes from "highcharts/modules/drag-panes";
import Annotations from "highcharts/modules/annotations-advanced";
import PriceIndicator from "highcharts/modules/price-indicator";
import FullScreen from "highcharts/modules/full-screen";
import StockTools from "highcharts/modules/stock-tools";
import HeikinAshi from "highcharts/modules/heikinashi";
import HollowCandlestick from "highcharts/modules/hollowcandlestick";
import Accessibility from "highcharts/modules/accessibility";

import "../static/css/HCCandleStickChart.css";

// import "highcharts/css/annotations/popup.css"
// import "highcharts/css/stocktools/gui.css"
// import "highcharts/css/highcharts.css"

const HCCandleStickChart = (symbol = "BTCUSD") => {
  // Initialize Highcharts modules
  Indicators(Highcharts);
  DragPanes(Highcharts);
  Annotations(Highcharts);
  PriceIndicator(Highcharts);
  FullScreen(Highcharts);
  StockTools(Highcharts);
  HeikinAshi(Highcharts);
  HollowCandlestick(Highcharts);
  Accessibility(Highcharts);
  useEffect(() => {
    (async () => {
      const colorTemplate =
        "{#ge point.open point.close}#ff6e6e{else}#51af7b{/ge}";
      const temp_data = await fetch(
        // "https://demo-live-data.highcharts.com/aapl-ohlcv.json"
        "https://api.delta.exchange/v2/history/candles?resolution=30d&symbol=BTCUSD&start=1619313068&end=1719432758"
      ).then((response) => response.json());
      let data = temp_data.result
      console.log(temp_data, data)
      const ohlc = [],
        volume = [],
        dataLength = data.length;

      for (let i = 0; i < dataLength; i += 1) {
        ohlc.push([
          data[i].time*1000, // the date
          data[i].open, // open
          data[i].high, // high
          data[i].low, // low
          data[i].close, // close
        ]);

        volume.push([
          data[i].time*1000, // the date
          data[i].volume,
          data[i].open < data[i].close
            ? "highcharts-point-up"
            : "highcharts-point-down", // the volume
        ]);
      }

      console.log(ohlc, volume)
      Highcharts.stockChart("container", {
        lang: {
          accessibility: {
            defaultChartTitle: "AAPL Stock Price",
          },
        },
        xAxis: {
          crosshair: {
            className: "highcharts-crosshair-custom",
            enabled: true,
          },
        },
        yAxis: [
          {
            title: {
              text: "price (USD)",
            },
            crosshair: {
              snap: false,
              className: "highcharts-crosshair-custom",
              label: {
                className: "highcharts-crosshair-custom-label",
                enabled: true,
                format: "{value:.2f}",
              },
              enabled: true,
            },
            labels: {
              align: "left",
            },
            height: "70%",
          },
          {
            title: {
              text: "volume",
            },
            crosshair: {
              className: "highcharts-crosshair-custom",
              snap: false,
              enabled: true,
              label: {
                format:
                  "{#if value ge 1000000} {(divide value 1000000):.2f} " +
                  "M {else} {value} {/if}",
                className: "highcharts-crosshair-custom-label",
                enabled: true,
              },
            },
            labels: {
              align: "left",
            },
            top: "70%",
            height: "30%",
            offset: 0,
          },
        ],
        chart: {
          styledMode: true,
        },
        tooltip: {
          shape: "square",
          split: false,
          shared: true,
          headerShape: "callout",
          shadow: false,
          format: `<span style="font-size: 1.4em">{point.series.name}</span>
O<span style="color:${colorTemplate}";>{point.open}</span>
H<span style="color:${colorTemplate}";>{point.high}</span>
L<span style="color:${colorTemplate}";>{point.low}</span>
C<span style="color:${colorTemplate}";>{point.close}
{(subtract point.open point.close):.2f}
{(multiply (divide (subtract point.open point.close) point.close) 100):.2f}%
</span>
<br>
Volume<span style="color:${colorTemplate}";>{points.1.y}</span>`,
          positioner: () => ({ x: 60, y: 0 }),
        },
        series: [
          {
            type: "candlestick",
            id: "aapl-ohlc",
            name: "AAPL Stock Price",
            lastPrice: {
              enabled: true,
              label: {
                enabled: true,
                align: "left",
                x: 8,
              },
            },
            data: ohlc,
          },
          {
            type: "column",
            lastPrice: {
              enabled: true,
              label: {
                format:
                  "{#if value ge 1000000} " +
                  "{(divide value 1000000):.2f} M {else} {value} {/if}",
                enabled: true,
                align: "left",
                x: 8,
              },
            },
            keys: ["x", "y", "className"],
            id: "aapl-volume",
            name: "AAPL Volume",
            data: volume,
            yAxis: 1,
          },
        ],
        rangeSelector: {
          verticalAlign: "bottom",
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 800,
              },
              chartOptions: {
                rangeSelector: {
                  inputEnabled: false,
                },
              },
            },
          ],
        },
      });
    })();
  }, [symbol]);

  return (
    <div id="QF_FUTPG_CS_CHART">
      <link
        href="https://code.highcharts.com/css/annotations/popup.css"
        rel="stylesheet"
      />
      <link
        href="https://code.highcharts.com/css/stocktools/gui.css"
        rel="stylesheet"
      />
      <link
        href="https://code.highcharts.com/css/highcharts.css"
        rel="stylesheet"
      />
      <figure className="highcharts-figure">
        <div id="container" className="chart highcharts-dark"></div>
      </figure>
    </div>
  );
};

export default HCCandleStickChart;
