import React, { useState } from "react";
import { chartColor } from "../../utils/sockets";
import ResearchStocks from "./ResearchStocks";
import StockSelector from "./StockSelector";

const MarketOverview = ({
  shortSellingData,
  fiiDiiData,
  tradingHoliday,
  clearingHoliday,
  gainerLoser,
}) => {
  const [whichHoliday, setWhichHoliday] = useState("TRADING");
  const [whichAdvancer, setWhichAdvancer] = useState("GAINER");
  return (
    <div>
      <center>
        <h3>MARKET OVERVIEW</h3>
      </center>
      <div className="row mt-2">
        <div className="col-md-9 h-min-full gutter-col-all">
          <StockSelector />
        </div>
        <div className="col-md-3 h-min-full gutter-col-all">
          <ResearchStocks />
        </div>
        <div className="col-md-3 gutter-col-all">
          <div
            className="general-nmphc-des p-2"
            style={{
              height: "100%",
            }}
          >
            <div
              className="general-nmphc-in-chart-i-des row m-0 tab-border"
              style={{
                height: "100%",
                overflowY: "scroll",
                "--bs-gutter-x": "1rem",
                "--bs-gutter-y": "1rem",
                padding: "0 10px 20px 10px",
              }}
            >
              <table
                style={{
                  width: "100%",
                  height: "100px",
                  padding: "10px",
                  fontSize: "14px",
                }}
              >
                <thead>
                  <tr>
                    <th
                      title="Short selling historical data in the National Stock Exchange (NSE) refers to the records of past transactions where investors have sold securities they do not own."
                      colSpan={3}
                      style={{
                        color: chartColor.red,
                      }}
                    >
                      Short Selling
                    </th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th>Symbol</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {shortSellingData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data["Trade Date"]}</td>
                        <td>{data["Symbol Name"]}</td>
                        <td>{data["Quantity"]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-3 gutter-col-all">
          <div className="general-nmphc-des p-2">
            <div
              className="row"
              style={{
                flexDirection: "column",
              }}
            >
              {fiiDiiData.map((val, index) => {
                return (
                  <div key={index} className="col-12">
                    <div
                      className=" general-nmphc-in-chart-i-des mt-1 mb-1 pt-2"
                      style={{
                        background:
                          val.netValue > 0
                            ? chartColor.opacGreen
                            : chartColor.opacRed,
                      }}
                    >
                      <p className="text-center">
                        {val["category"].replace(/\*/g, "").trim()}
                      </p>
                      <center>
                        <table
                          className="m-2"
                          style={{ width: "90%", fontSize: "14px" }}
                        >
                          <tbody>
                            <tr>
                              <th>Buy Value (cr):</th>
                              <td style={{ textAlign: "right" }}>
                                {val.buyValue}
                              </td>
                            </tr>
                            <tr>
                              <th>Sell Value (cr):</th>
                              <td style={{ textAlign: "right" }}>
                                {val.sellValue}
                              </td>
                            </tr>
                            <tr>
                              <th>Net:</th>
                              <td style={{ textAlign: "right" }}>
                                {val.netValue}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </center>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="col-md-3 h-min-full gutter-col-all"
          style={{
            fontWeight: "700",
            fontSize: "14px",
          }}
        >
          <div className="general-nmphc-des h-min-full p-2">
            <div className="row px-1">
              <div className="p-2 col-6">
                <div
                  className={
                    (whichAdvancer == "GAINER"
                      ? "general-nmphc-in-chart-i-des"
                      : "general-nmphc-des") + " text-center mt-0 c-pointer"
                  }
                  onClick={() => {
                    setWhichAdvancer("GAINER");
                  }}
                >
                  GAINER
                </div>
              </div>
              <div className="p-2 col-6">
                <div
                  className={
                    (whichAdvancer == "LOSER"
                      ? "general-nmphc-in-chart-i-des"
                      : "general-nmphc-des") + " text-center mt-0 c-pointer"
                  }
                  onClick={() => {
                    setWhichAdvancer("LOSER");
                  }}
                >
                  LOSER
                </div>
              </div>
            </div>
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "200px",
              }}
            >
              <table
                className="tab-border"
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <div className="row p-2">
                        <div className="col-12">
                          <div
                            style={{
                              height: "20px",
                              borderRadius: "100px",
                              backgroundImage: `linear-gradient(to right, 
                            ${
                              gainerLoser["counts"]?.advances
                                ? `rgba(0, 128, 0, ${
                                    (gainerLoser["counts"]?.advances /
                                      (gainerLoser["counts"]?.advances +
                                        gainerLoser["counts"]?.unchanged +
                                        gainerLoser["counts"]?.declines)) *
                                    100
                                  }%)`
                                : "transparent"
                            },
                            ${
                              gainerLoser["counts"]?.unchanged
                                ? `rgba(255, 255, 255, ${
                                    (gainerLoser["counts"]?.unchanged /
                                      (gainerLoser["counts"]?.advances +
                                        gainerLoser["counts"]?.unchanged +
                                        gainerLoser["counts"]?.declines)) *
                                    100
                                  }%)`
                                : "transparent"
                            },
                            ${
                              gainerLoser["counts"]?.declines
                                ? `rgba(255, 0, 0, ${
                                    (gainerLoser["counts"]?.declines /
                                      (gainerLoser["counts"]?.advances +
                                        gainerLoser["counts"]?.unchanged +
                                        gainerLoser["counts"]?.declines)) *
                                    100
                                  }%)`
                                : "transparent"
                            })`,
                            }}
                          ></div>{" "}
                        </div>
                        <div className="col-4">
                          {gainerLoser["counts"]?.advances || 0}
                        </div>
                        <div className="col-4 text-center">
                          {gainerLoser["counts"]?.unchanged || 0}
                        </div>
                        <div className="col-4 text-end">
                          {gainerLoser["counts"]?.declines || 0}
                        </div>
                      </div>
                    </td>
                  </tr>
                  {(
                    (whichAdvancer == "GAINER"
                      ? gainerLoser["gainers"]
                      : gainerLoser["losers"]) || []
                  ).map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data["symbol"]}</td>
                        <td>{data["pChange"]}%</td>
                        {/* <td>{data["totalTradedVolume"]}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="col-md-3 h-min-full gutter-col-all"
          style={{
            fontWeight: "700",
            fontSize: "14px",
          }}
        >
          <div className="general-nmphc-des h-min-full p-2">
            <div className="row px-1">
              <div className="p-2 col-6">
                <div
                  className={
                    (whichHoliday == "TRADING"
                      ? "general-nmphc-in-chart-i-des"
                      : "general-nmphc-des") + " text-center mt-0 c-pointer"
                  }
                  onClick={() => {
                    setWhichHoliday("TRADING");
                  }}
                >
                  TRADING
                </div>
              </div>
              <div className="p-2 col-6">
                <div
                  className={
                    (whichHoliday == "CLEARING"
                      ? "general-nmphc-in-chart-i-des"
                      : "general-nmphc-des") + " text-center mt-0 c-pointer"
                  }
                  onClick={() => {
                    setWhichHoliday("CLEARING");
                  }}
                >
                  CLEARING
                </div>
              </div>
            </div>
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "200px",
              }}
            >
              <table className="tab-border">
                <tbody>
                  {(whichHoliday == "TRADING"
                    ? tradingHoliday
                    : clearingHoliday
                  ).map((data, index) => {
                    return (
                      <tr
                        key={index}
                        style={
                          data["weekDay"] == "Saturday" ||
                          data["weekDay"] == "Sunday"
                            ? {
                                opacity: "0.5",
                              }
                            : {}
                        }
                      >
                        <td>{data["tradingDate"]}</td>
                        <td>{data["weekDay"]}</td>
                        <td>{data["description"]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketOverview;
