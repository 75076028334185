import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { commonApiGet } from "../../../utils/api";
import { searchTerm } from "../../../utils/functions";
import { chartColor } from "../../../utils/sockets";

const Forecast = ({ symbol = null, searchTermProp = null }) => {
  const [options, setOptions] = useState({
    chart: {
      height: 100,
      backgroundColor: null,
      margin: [0, 0, 0, 0],
    },
    title: {
      text: null, // Hide title
    },
    subtitle: {
      text: null, // Hide subtitle
    },
  });
  const [forecastData, setForecastData] = useState({
    graphData: [],
    high: 0,
    mean: 0,
    low: 0,
  });

  useEffect(() => {
    (async () => {
      let symToLook = symbol;
      if (symToLook === null) {
        symToLook = (await searchTerm(searchTermProp))?.sc_id;
      }
      await commonApiGet(
        `/stocks/stockestimates?stock=${symToLook}&estimate=Price-forecast`
      )
        .then((res) => {
          setForecastData(res.data);
          console.log(res.data, "Katil-e-taliban");
        })
        .catch((err) => {
          setForecastData({
            graphData: [],
            high: 0,
            mean: 0,
            low: 0,
          });
          console.error(err);
        });
    })();
  }, [symbol, searchTermProp]);

  useEffect(() => {
    if (forecastData.graphData.length == 0) return;
    let isPriceIncreased24Hrs =
      forecastData.graphData[0][1] <
      forecastData.graphData[forecastData.graphData.length - 1][1];
    let color = isPriceIncreased24Hrs ? chartColor.green : chartColor.red;
    let opacColor = isPriceIncreased24Hrs
      ? chartColor.opacGreen
      : chartColor.opacRed;
    const optionsTemp = {
      chart: {
        height: 100,
        backgroundColor: null,
        margin: [0, 0, 0, 0],
      },
      title: {
        text: null, // Hide title
      },
      subtitle: {
        text: null, // Hide subtitle
      },
      xAxis: {
        type: "datetime",
        labels: {
          format: "{value:%d-%b-%Y}",
        },
        dateTimeLabelFormats: {
          day: "%d-%b-%Y",
        },
        title: {
          text: null,
        },
        lineColor: "transparent",
        tickLength: 0,
      },
      yAxis: {
        title: {
          text: null, // Hide y-axis title
        },
        labels: {
          enabled: false, // Hide y-axis labels
        },
        gridLineColor: "transparent", // Hide y-axis grid lines
      },
      legend: {
        enabled: false, // Hide legend
      },
      plotOptions: {
        area: {
          color: opacColor,
          marker: {
            radius: 0,
          },
          states: {
            hover: {
              lineWidth: 0,
            },
          },
          threshold: null,
          lineColor: color,
          lineWidth: 3,
        },
      },
      series: [
        {
          type: "area",
          name: searchTermProp || symbol,
          data: forecastData.graphData.map((val) => {
            const unixDate = val[0] * 1000;
            const d = new Date(unixDate);
            return [d.toLocaleString(), val[1]];
          }),
          lineColor: color,
          color: opacColor,
        },
      ],
    };
    setOptions(optionsTemp);
  }, [forecastData]);

  return (
    <>
      <div className="row">
        {forecastData.graphData.length > 0 ? (
          <>
            <div className="col-9">
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <div
              className="col-3"
              style={{
                fontWeight: "bolder",
                alignContent: "center",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  color: chartColor.green,
                }}
              >
                {forecastData?.high}
              </div>
              <div
                style={{
                  color: chartColor.yellow,
                }}
              >
                {forecastData?.mean}
              </div>
              <div
                style={{
                  color: chartColor.red,
                }}
              >
                {forecastData?.low}
              </div>
            </div>
          </>
        ) : (
          <div
            className="col-12"
            style={{
              fontSize: "14px",
            }}
          >
            ---No data---
          </div>
        )}
      </div>
    </>
  );
};

export default Forecast;
