import axios from "axios";
import { nseBase } from "./sockets";

export const backURL = nseBase;

export const errorMsg = "Contact Server Admin || Something unexpected happen";

const req = axios.create({
  baseURL: backURL,
});

function getCookie(c_name) {
  let c_start, c_end;
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

req.interceptors.request.use(async (config) => {
  const csrftoken = getCookie("csrftoken");
  const accesstoken = getCookie("accesstoken");
  if (accesstoken) {
    config.headers.Authorization = accesstoken;
  }
  if (csrftoken) {
    config.headers["X-CSRFToken"] = csrftoken;
  }
  config.headers.ContentType = "application/json";
  return config;
});

export const commonApiGet = async (url, data={}) => {
  return await req
    .get((url).replace("%", "%25"))
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

export const commonApiPost = async (url, data) => {
  return await req
    .post(url, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

export const commonApiPut = async (url, data) => {
  return await req
    .put(url, data)
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

export const commonApiDelete = async (url, data = null) => {
  const token = localStorage.getItem("token");
  if (data === null) {
    return await req
      .delete(url)
      .then((response) => {
        return response;
      })
      .catch(async (err) => {
        await postApiReqHandlation(err);
      });
  } else {
    var config = {
      method: "delete",
      url: backURL + url,
      headers: {
        Authorization: token ? token : "",
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(config)
      .then((response) => {
        return response;
      })
      .catch(async (err) => {
        await postApiReqHandlation(err);
      });
  }
};

export const fileUploadApi = async (url, data) => {
  return await req
    .post(url, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

const postApiReqHandlation = async (err) => {
  console.log(err);
  
  const response = err.response;
  if (response.status === 601) {
    // removeUserSession();
    throw err;
  } else if (response.status === 603) {
    location.replace("/await-verification");
  } else {
    throw err;
  }
};
