import React, { Component } from "react";

import store from "../utils/glob-redux/store";
import AppRoutes from "./AppRoutes";
import { Provider, useSelector } from "react-redux";

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    );
  }
}

// const root = createRoot(document.getElementById("app"));
