import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { chartColor } from "../utils/sockets";
import { useSelector } from "react-redux";

const OHLCChart = ({ ohlc = [[0, 0, 0, 0]] }) => {
  const theme = useSelector((state) => state.theme);

  const options = {
    chart: {
      type: "candlestick",
      spacing: [0, 0, 0, 0],
      backgroundColor: null,
      height: 150,
      width: 25,
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      candlestick: {
        borderWidth: 0,
        enableMouseTracking: false, // Disable hover functionality
      },
    },
    series: [
      {
        type: "candlestick",
        data: ohlc, // [open, high, low, close]
        lineColor: theme == "dark" ? "white" : "black",
        upColor: chartColor.green,
        color: chartColor.red,
        borderWidth: 0,
      },
    ],
  };

  return <HighchartsReact options={options} highcharts={Highcharts} />;
};

export default OHLCChart;
