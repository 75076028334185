import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { chartColor } from "../../utils/sockets";
import { Link } from "react-router-dom";
import trendLogo from "../../static/images/trend.png"; // Tell webpack this JS file uses this image

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import "./styles.css";

// import required modules
import { EffectCards } from "swiper/modules";

import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Forecast from "./ChartComponents/Forecast";

const StockSelector = () => {
  const [bus, setbus] = useState([]);
  const [dropdownbusOpen, setDropdownbusOpen] = useState(false);
  const builupOptions = [
    "longbuildup",
    "shortbuildup",
    "longunwinding",
    "shortcovering",
  ];
  const [selectedbusOption, setSelectedbusOption] = useState(builupOptions[0]);
  const bustoggle = () => setDropdownbusOpen((prevState) => !prevState);

  const [bos, setbos] = useState([]);
  const [dropdownbosOpen, setDropdownbosOpen] = useState(false);
  const breakoutOptions = ["Volume", "Bullish", "Bearish"];
  const breakoutOptionsKey = {
    Volume: "Volume",
    Bullish: "52 Week High",
    Bearish: "52 Week Low",
  };
  const [selectedbosOption, setSelectedbosOption] = useState(
    breakoutOptions[0]
  );
  const bostoggle = () => setDropdownbosOpen((prevState) => !prevState);

  const [tnds, settnds] = useState([]);
  const [dropdowntndsOpen, setDropdowntndsOpen] = useState(false);
  const tndsOptions = [
    "Bullish",
    "Bearish",
    "Turning-bullish",
    "Turning-bearish",
  ];
  const [selectedtndsOption, setSelectedtndsOption] = useState(tndsOptions[0]);
  const tndstoggle = () => setDropdowntndsOpen((prevState) => !prevState);

  const [dropdowntnds2Open, setDropdowntnds2Open] = useState(false);
  const tnds2Options = ["FNO", "LCAP", "MDCAP", "SMCAP"];
  const [selectedtnds2Option, setSelectedtnds2Option] = useState(
    tnds2Options[0]
  );
  const tnds2toggle = () => setDropdowntnds2Open((prevState) => !prevState);

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/analysis/stocksbybuildup?buildup=${selectedbusOption}`
      )
        .then((res) => {
          console.log(res, "6699");
          setbus(Array.isArray(res.data) ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, [selectedbusOption]);

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/analysis/stocksbybreakout?breakout=${selectedbosOption}`
      )
        .then((res) => {
          setbos(Array.isArray(res.data) ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, [selectedbosOption]);

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/analysis/stocksbytrend?trend=${selectedtndsOption}&collection=${selectedtnds2Option}`
      )
        .then((res) => {
          settnds(Array.isArray(res.data) ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, [selectedtndsOption, selectedtnds2Option]);

  return (
    <div className="general-nmphc-in-chart-i-des p-1 h-min-full row m-0">
      <div className="p-1 col-md-4 h-min-full m-0 mt-2">
        <div className=" m-0 h-100 p-2">
          <div className="row p-1">
            <div className="col-6">
              <h6 className="p-1">Buildup Stocks</h6>
            </div>
            <div className="col-6">
              <div
                className="col-12"
                style={{
                  textAlign: "end",
                }}
              >
                <ButtonDropdown isOpen={dropdownbusOpen} toggle={bustoggle}>
                  <DropdownToggle
                    caret
                    size="sm"
                    color="transparent"
                    className="general-nmphc-des text-color-themed"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {selectedbusOption.toUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu
                    className="general-nmphc-des non-trans-bg"
                    style={{
                      maxHeight: "220px",
                      overflowY: "scroll",
                    }}
                  >
                    {builupOptions.map((val, key) => (
                      <DropdownItem
                        className="bg-set-on-active text-color-themed"
                        key={`BUS_${key}`}
                        onClick={() => {
                          setSelectedbusOption(val);
                        }}
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {val.toUpperCase()}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </div>
          </div>

          <hr className="m-0" />

          {console.log(bus, "busss")}
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            {bus.map((val, key) => {
              return (
                <SwiperSlide
                  key={`selectedbusOption_${selectedbusOption}_${key}`}
                >
                  <h6 className="text-center">{val.Company}</h6>
                  <hr className="m-0" />
                  <table
                    className="table-td-r w-100 mt-2"
                    style={{
                      fontSize: "12px",
                      fontWeight: "lighter",
                    }}
                  >
                    <tbody>
                      <tr>
                        <th>Updated at:</th>
                        <td>{val.Date}</td>
                      </tr>
                      <tr>
                        <th>CMP:</th>
                        <td>₹{val.Price}</td>
                      </tr>
                      <tr>
                        <th>Current Trend:</th>
                        <td>{val["Price Percentage"]}</td>
                      </tr>
                      <tr>
                        <th>OI:</th>
                        <td>{val["OI"]}</td>
                      </tr>
                      <tr>
                        <th>OI Percentage:</th>
                        <td>{val["OI Percentage"]}</td>
                      </tr>
                    </tbody>
                  </table>
                  <center>
                    {/* <img
                      src={trendLogo}
                      alt={val.StockName}
                      height={120}
                      style={{
                        marginTop: "10px",
                      }}
                    /> */}
                    <Forecast searchTermProp={val.Company} />
                  </center>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      <div className="p-1 col-md-4 h-min-full m-0 mt-2">
        <div className=" m-0 h-100 p-2">
          <div className="row p-1">
            <div className="col-6">
              <h6 className="p-1">Breakout Scanner</h6>
            </div>
            <div className="col-6">
              <div
                className="col-12"
                style={{
                  textAlign: "end",
                }}
              >
                <ButtonDropdown isOpen={dropdownbosOpen} toggle={bostoggle}>
                  <DropdownToggle
                    caret
                    size="sm"
                    color="transparent"
                    className="general-nmphc-des text-color-themed"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {selectedbosOption.toUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu
                    className="general-nmphc-des non-trans-bg"
                    style={{
                      maxHeight: "220px",
                      overflowY: "scroll",
                    }}
                  >
                    {breakoutOptions.map((val, key) => (
                      <DropdownItem
                        className="bg-set-on-active text-color-themed"
                        key={`BOS_${key}`}
                        onClick={() => {
                          setSelectedbosOption(val);
                        }}
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {val.toUpperCase()}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </div>
          </div>

          <hr className="m-0" />

          {console.log(bos, "bosss")}

          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            {bos.map((val, key) => {
              return (
                <SwiperSlide
                  key={`selectedbosOption_${selectedbosOption}_${key}`}
                >
                  <h6 className="text-center">{val["Stock Name"]}</h6>
                  <hr className="m-0" />
                  <table
                    className="table-td-r w-100 mt-2"
                    style={{
                      fontSize: "12px",
                      fontWeight: "lighter",
                    }}
                  >
                    <tbody>
                      <tr>
                        <th>CMP:</th>
                        <td>₹{val["Current Price"]}</td>
                      </tr>
                      <tr>
                        <th>{breakoutOptionsKey[selectedbosOption]}:</th>
                        <td>{val[breakoutOptionsKey[selectedbosOption]]}</td>
                      </tr>
                    </tbody>
                  </table>
                  <center>
                    {/* <img
                      src={trendLogo}
                      alt={val.StockName}
                      height={120}
                      style={{
                        marginTop: "10px",
                      }}
                    /> */}
                    <Forecast searchTermProp={val["Stock Name"]} />
                  </center>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      <div className="p-1 col-md-4 h-min-full m-0 mt-2">
        <div className=" m-0 h-100 p-2">
          <div className="row p-1">
            <div className="col-4">
              <h6 className="p-1">Trending</h6>
            </div>
            <div className="col-8">
              <div
                className="col-12"
                style={{
                  textAlign: "end",
                }}
              >
                <ButtonDropdown
                  className="mx-1"
                  isOpen={dropdowntndsOpen}
                  toggle={tndstoggle}
                >
                  <DropdownToggle
                    caret
                    size="sm"
                    color="transparent"
                    className="general-nmphc-des text-color-themed"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {selectedtndsOption.toUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu
                    className="general-nmphc-des non-trans-bg"
                    style={{
                      maxHeight: "220px",
                      overflowY: "scroll",
                    }}
                  >
                    {tndsOptions.map((val, key) => (
                      <DropdownItem
                        className="bg-set-on-active text-color-themed"
                        key={`tnds_${key}`}
                        onClick={() => {
                          setSelectedtndsOption(val);
                        }}
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {val.toUpperCase()}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown
                  className="mx-1"
                  isOpen={dropdowntnds2Open}
                  toggle={tnds2toggle}
                >
                  <DropdownToggle
                    caret
                    size="sm"
                    color="transparent"
                    className="general-nmphc-des text-color-themed"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {selectedtnds2Option.toUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu
                    className="general-nmphc-des non-trans-bg"
                    style={{
                      maxHeight: "220px",
                      overflowY: "scroll",
                    }}
                  >
                    {tnds2Options.map((val, key) => (
                      <DropdownItem
                        className="bg-set-on-active text-color-themed"
                        key={`TNDS2_${key}`}
                        onClick={() => {
                          setSelectedtnds2Option(val);
                        }}
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {val.toUpperCase()}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </div>
          </div>

          <hr className="m-0" />
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            {tnds.map((val, key) => {
              return (
                <SwiperSlide key={`selectedtndsOption_${selectedtndsOption}_selectedtnds2Option_${selectedtnds2Option}_${key}`}>
                  <h6 className="text-center">{val.StockName}</h6>
                  <hr className="m-0" />
                  <table
                    className="table-td-r w-100 mt-2"
                    style={{
                      fontSize: "12px",
                      fontWeight: "lighter",
                    }}
                  >
                    <tbody>
                      <tr>
                        <th>Updated at:</th>
                        <td>{val.updatedTime}</td>
                      </tr>
                      <tr>
                        <th>CMP:</th>
                        <td>₹{val.currPrice}</td>
                      </tr>
                      <tr>
                        <th>Current Trend:</th>
                        <td>{val.currTrend}</td>
                      </tr>
                      <tr>
                        <th>Performance:</th>
                        <td>{val.performance}%</td>
                      </tr>
                      <tr>
                        <th>Trend Change:</th>
                        <td>{val.trendChngDate}</td>
                      </tr>
                      <tr>
                        <th>Trend Change Price:</th>
                        <td>₹{val.trendChngPrice}</td>
                      </tr>
                      <tr>
                        <th>Prev Trend:</th>
                        <td>{val.prevTrend}</td>
                      </tr>
                    </tbody>
                  </table>
                  <center>
                    {/* <img
                      src={trendLogo}
                      alt={val.StockName}
                      height={120}
                      style={{
                        marginTop: "10px",
                      }}
                    /> */}

                    <Forecast searchTermProp={val.StockName} />
                  </center>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {console.log(tnds)}
        </div>
      </div>
    </div>
  );
};

export default StockSelector;
