import React, { useEffect, useState } from "react";
import { prepareCyptoData } from "../utils/functions";
import { defaultValuesForCryptoPrice } from "../utils/defaultCardValue";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import {
  chartColor,
  socketFieldForMultiExchange,
  tickerSymsExchange,
} from "../utils/sockets";
import SparklineChart from "./SparklineChart";
import AskBidChart from "./AskBidChart";
import OhlcuclcComp from "./OhlcuclcComp";

export const HomePageCryptoCard = ({
  cryptoInfo,
  forTicker,
  forSpot,
  sparklinesym,
}) => {
  const [cryptoInfoState, setCryptoInfoState] = useState(cryptoInfo);
  const [ApiLoad, setApiLoad] = useState(0);
  const [DefaultApiLoad, setDefaultApiLoad] = useState(0);
  const exchange = useSelector((state) => state.exchange);
  const [loadohlcuclc, setLoadohlcuclc] = useState(false);
  let prepareDataToLoad = cryptoInfoState;

  useEffect(() => {
    if (ApiLoad !== 0) {
      // Establish WebSocket connection
      const socket = new WebSocket(socketFieldForMultiExchange[exchange]);

      // WebSocket event listeners
      socket.onopen = () => {
        console.log("WebSocket connected");
        const subscriptionMessage = JSON.stringify({
          type: "subscribe",
          payload: {
            channels: [
              {
                name: "v2/ticker",
                symbols: [tickerSymsExchange[forTicker][exchange]],
              },
              {
                name: "v2/spot_price",
                symbols: [forSpot],
              },
            ],
          },
        });

        socket.send(subscriptionMessage);
        console.log("Subscribed to v2 ticker");
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "v2/ticker") {
          prepareDataToLoad = prepareCyptoData(data, prepareDataToLoad);
          setCryptoInfoState(prepareDataToLoad);
        }
        if (data.type === "v2/spot_price") {
          prepareDataToLoad = prepareCyptoData(data, prepareDataToLoad, "spot");
          setCryptoInfoState(prepareDataToLoad);
        }
        // Handle incoming messages from the WebSocket server
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected");
      };

      // Clean-up function
      return () => {
        // Close WebSocket connection when component unmounts
        socket.close();
      };
    }
  }, [ApiLoad, exchange]);
  useEffect(() => {
    async function intiateDefaultValues() {
      await defaultValuesForCryptoPrice(forTicker, exchange).then((data) => {
        setCryptoInfoState(data);
        setApiLoad(1);
      });
    }
    if (DefaultApiLoad) intiateDefaultValues();
  }, [DefaultApiLoad, exchange]);
  useEffect(() => {
    setCryptoInfoState(cryptoInfo);
    setDefaultApiLoad(1);
    setApiLoad(0);
  }, [exchange]);

  return (
    <SkeletonTheme inline={true} width={68} borderRadius={30}>
      <div id={cryptoInfoState.id} className="card general-nmphc-des">
        <div className="general-nmphc-des div-pill-right">{exchange}</div>
        <div className="general-nmphc-des div-pill-left">
          {cryptoInfoState.symbol}
        </div>
        <SparklineChart sparklinesym={sparklinesym} />
        <AskBidChart
          quotes={{
            ask_size: cryptoInfoState.quotes?.ask_size || 0,
            best_ask: cryptoInfoState.quotes?.best_ask || 0,
            best_bid: cryptoInfoState.quotes?.best_bid || 0,
            bid_size: cryptoInfoState.quotes?.bid_size || 0,
          }}
        />
        <h3>
          {cryptoInfoState.inr?.spotPrice || <Skeleton />}{" "}
          <span
            style={{
              color:
                cryptoInfoState.mark_change_24h < 0
                  ? chartColor.green
                  : chartColor.red,
            }}
          >
            (
            {Math.abs(parseFloat(cryptoInfoState.mark_change_24h) || 0).toFixed(
              2
            ) || <Skeleton />}
            %)
          </span>
        </h3>
        <h1>
          {cryptoInfoState.usd?.spotPrice || <Skeleton />}{" "}
          {cryptoInfoState.incDec?.newSpot > cryptoInfoState.incDec?.oldSpot ? (
            <sup
              className="fas fa-caret-up"
              style={{
                color: chartColor.green,
              }}
            ></sup>
          ) : (
            <sup
              className="fas fa-caret-down"
              style={{
                color: chartColor.red,
              }}
            ></sup>
          )}
        </h1>
        <p>
          Mark Price: <span>{cryptoInfoState.markPrice || <Skeleton />}</span>
        </p>
        <p>
          <span title="8Hours">Funding Rate: </span>{" "}
          <span>
            {parseFloat(cryptoInfoState.funding_rate || 0).toFixed(4) || (
              <Skeleton />
            )}
          </span>{" "}
          | IV:{" "}
          <span>
            {parseFloat(cryptoInfoState.quotes?.mark_iv || 0).toFixed(2) || (
              <Skeleton />
            )}
          </span>
        </p>

        <p>
          Volume 24h: <span>{cryptoInfoState.usd?.volume || <Skeleton />}</span>{" "}
          | <span>{cryptoInfoState.inr?.volume || <Skeleton />}</span>
        </p>
        <p>
          Open Interest: <span>{cryptoInfoState.usd?.oi || <Skeleton />}</span>{" "}
          | <span>{cryptoInfoState.inr?.oi || <Skeleton />}</span>
        </p>
        <p>
          OI change 6h:{" "}
          <span
            style={{
              color:
                (cryptoInfoState.usd?.oiChange || 0)[0] == "-"
                  ? chartColor.red
                  : chartColor.green,
              fontWeight: "bold",
            }}
          >
            {cryptoInfoState.usd?.oiChange || <Skeleton />}
          </span>{" "}
          |{" "}
          <span
            style={{
              color:
                (cryptoInfoState.usd?.oiChange || 0)[0] == "-"
                  ? chartColor.red
                  : chartColor.green,
              fontWeight: "bold",
            }}
          >
            {cryptoInfoState.inr?.oiChange || <Skeleton />}
          </span>
        </p>
        <center>
          <i
            style={{
              cursor: "pointer",
            }}
            className={
              loadohlcuclc ? "fas fa-chevron-up" : "fas fa-chevron-down"
            }
            onClick={() => setLoadohlcuclc(!loadohlcuclc)}
          ></i>
        </center>
        {loadohlcuclc ? (
          <OhlcuclcComp
            open={cryptoInfoState.open}
            high={cryptoInfoState.high}
            low={cryptoInfoState.low}
            close={cryptoInfoState.close}
            uc={cryptoInfoState.price_band?.upper_limit || 0}
            lc={cryptoInfoState.price_band?.lower_limit || 0}
          />
        ) : (
          <></>
        )}
      </div>
    </SkeletonTheme>
  );
};
