import { nseBaseDocs } from "../utils/sockets";


import React from 'react';

const IframeComponent = () => {
  return (
    <div>
      <iframe
        title="External Content"
        src={nseBaseDocs}
        style={{ border: 'none', height: '100vh', overflow: 'scroll', width: '100vw' }}
      />
    </div>
  );
};


export const NSEDocs = () => {
  return (
      <IframeComponent />
  );
};
