import React, { useEffect, useState } from "react";
import {
  candleCountAccordingTimeframes,
  chartColor,
} from "../../utils/sockets";
// import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Highcharts from "highcharts/highstock";
import { commonApiGet } from "../../utils/api";
import { convertToDateNSE, date4IndexHis } from "../../utils/sockets";
const SparklineChartNSE = ({ sparklinesym }) => {
  const theme = useSelector((state) => state.theme);
  const [chartData, setChartData] = useState([]);
  const [chartHistoricData, setChartHistoricData] = useState([]);
  const [options, setOptions] = useState({});
  const [isWindowChartCandle, setIsWindowChartCandle] = useState(false);
  const [resolution, setResolution] = useState("1M");
  useEffect(() => {
    (async () => {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      await commonApiGet(
        `/indices/index_history/${sparklinesym.replace('/','%2F')}?start_date=${date4IndexHis[resolution]}&end_date=${date4IndexHis.current}`
      )
        .then((result) => {
          let data = result.data.indexCloseOnlineRecords;
          console.log(data, "ytyt");
          setChartHistoricData(
            data.map((val) => ({
              time: convertToDateNSE(val.EOD_TIMESTAMP),
              open: Number(val.EOD_OPEN_INDEX_VAL),
              high: Number(val.EOD_HIGH_INDEX_VAL),
              low: Number(val.EOD_LOW_INDEX_VAL),
              close: Number(val.EOD_CLOSE_INDEX_VAL),
            }))
          );
        })
        .catch((error) => console.error(error));
    })();
  }, [sparklinesym, resolution]);

  useEffect(() => {
    if (!isWindowChartCandle) {
      setChartData(
        chartHistoricData
          .sort((a, b) => a.time - b.time)
          .map((val) => [val.time, val.close])
      );
    } else {
      setChartData(
        chartHistoricData
          .sort((a, b) => a.time - b.time)
          .map((val) => [val.time, val.open, val.high, val.low, val.close])
      );
    }
  }, [chartHistoricData, isWindowChartCandle]);

  useEffect(() => {
    if (chartData.length < 1) {
      return;
    }
    let isPriceIncreased24Hrs =
      chartData[0][1] < chartData[chartData.length - 1][1];
    let color = isPriceIncreased24Hrs ? chartColor.green : chartColor.red;
    let opacColor = isPriceIncreased24Hrs
      ? chartColor.opacGreen
      : chartColor.opacRed;
    if (isWindowChartCandle) {
      console.log(222, chartData);
      setOptions({
        chart: {
          height: 300,
          zoomType: "x", // Adjusted zoom type
          backgroundColor: null,
          margin: [0, 0, 0, 0],
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          type: "datetime",
          labels: {
            format: "{value:%d-%b-%Y}",
          },
          dateTimeLabelFormats: {
            day: "%d-%b-%Y",
          },
          title: {
            text: null,
          },
          lineColor: "transparent",
          tickLength: 0,
          min: chartData.length > 60 ? chartData.length - 60 : 0,
          max: chartData.length - 1,
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          gridLineColor: "transparent",
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            type: "candlestick", // Changed to candlestick type
            name: sparklinesym,
            data: chartData.map((val) => {
              const unixDate = val[0];
              const d = new Date(unixDate);
              return [d.toLocaleString(), val[1], val[2], val[3], val[4]]; // OHLC data format
            }),
            lineColor: theme == "dark" ? "white" : "black",
            upColor: chartColor.green,
            color: chartColor.red,
          },
        ],
      });
    } else {
      console.log(111);
      setOptions({
        chart: {
          height: 300,
          zooming: {
            type: "x",
          },
          backgroundColor: null,
          margin: [0, 0, 0, 0],
        },
        title: {
          text: null, // Hide title
        },
        subtitle: {
          text: null, // Hide subtitle
        },
        xAxis: {
          type: "datetime",
          labels: {
            format: "{value:%d-%b-%Y}",
          },
          dateTimeLabelFormats: {
            day: "%d-%b-%Y",
          },
          title: {
            text: null,
          },
          lineColor: "transparent",
          tickLength: 0,
          min: chartData.length > 40 ? chartData.length - 40 : 0,
          max: chartData.length - 1,
        },
        yAxis: {
          title: {
            text: null, // Hide y-axis title
          },
          labels: {
            enabled: false, // Hide y-axis labels
          },
          gridLineColor: "transparent", // Hide y-axis grid lines
        },
        legend: {
          enabled: false, // Hide legend
        },
        plotOptions: {
          area: {
            color: opacColor,
            marker: {
              radius: 0,
            },
            states: {
              hover: {
                lineWidth: 0,
              },
            },
            threshold: null,
            lineColor: color,
            lineWidth: 3,
          },
        },
        series: [
          {
            type: "area",
            name: sparklinesym,
            data: chartData.map((val) => {
              const unixDate = val[0];
              const d = new Date(unixDate);
              return [d.toLocaleString(), val[1]];
            }),
            lineColor: color,
            color: opacColor,
          },
        ],
      });
    }
  }, [chartData, theme]);

  // Your Highcharts code here

  return (
    <div
      className="general-nmphc-des"
      style={{
        overflow: "hidden",
      }}
    >
      <SkeletonTheme borderRadius={30} width={50}>
        {options.hasOwnProperty("chart") ? (
          <>
            {date4IndexHis.options.map((val, key) => (
              <span
                onClick={() => {
                  setResolution(val);
                }}
                className={
                  "div-pill rounded m-1 " +
                  (resolution == val ? "our-border" : "")
                }
                key={`${sparklinesym}_resolutionAvailable_${key}`}
              >
                {val}
              </span>
            ))}
            <i
              className={
                "div-pill m-1 general-nmphc-des our-border " +
                (!isWindowChartCandle
                  ? "fas fa-chart-column"
                  : "fas fa-chart-line")
              }
              onClick={() => setIsWindowChartCandle(!isWindowChartCandle)}
            ></i>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </>
        ) : (
          <center>
            <Skeleton />
          </center>
        )}
      </SkeletonTheme>
    </div>
  );
};

export default SparklineChartNSE;
