import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { chartColor, indiaProductApi } from "../utils/sockets";
import HCCandleStickChart from "../components/HCCandleStickChart";

export const FuturesPage = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [indices, setIndices] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState({
    symbol: "BTCUSD"
  });

  const [activeChart, setActiveChart] = useState("CS"); // CandleStick: CS, Tech Analysis: TA, Depth: D, FundingRate: FR

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  function compare(a, b) {
    if (
      a.underlying_asset?.sort_priority ||
      0 < b.underlying_asset?.sort_priority ||
      0
    ) {
      return -1;
    }
    if (
      a.underlying_asset?.sort_priority ||
      0 > b.underlying_asset?.sort_priority ||
      0
    ) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    (async () => {
      // Fetch all cryptos indices

      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        indiaProductApi + "?contract_types=perpetual_futures&states=live",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const data = JSON.parse(result).result || [];

          setIndices(data.sort(compare));
        })
        .catch((error) => console.error(error));
    })();
  }, []);
  return (
    <div className="container-fluid">
      <Navbar />

      <div className="main-content">
        <div className="row ">
          <div
            className="futures-card general-nmphc-in-chart-i-des col-12 "
            style={{
              overflow: "unset",
            }}
          >
            <div className="row">
              <div className="col-12">
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    caret
                    size="sm"
                    color="transparent"
                    className="general-nmphc-des text-color-themed"
                  >
                    {selectedIndex.symbol}
                  </DropdownToggle>
                  <DropdownMenu
                    className="general-nmphc-des non-trans-bg"
                    style={{
                      maxHeight: "220px",
                      overflowY: "scroll",
                    }}
                  >
                    {indices.map((val, key) => {
                      return (
                        <DropdownItem
                          className="bg-set-on-active text-color-themed"
                          key={`futures_${val.symbol || "symbol"}_${key}`}
                          onClick={() => {
                            setSelectedIndex(val);
                          }}
                        >
                          {`${val.symbol} | ${val.underlying_asset?.name} `}
                          <span className="div-pill full-rounded our-border">
                            {`${parseInt(val.default_leverage)}x`}
                          </span>
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

              <div className="col-md-8 mt-3">
                <div className="row p-2">
                  <span
                    className={
                      "div-pill mx-2 p-1 general-nmphc-des col text-center" +
                      (activeChart == "CS" ? " our-border" : "")
                    }
                    onClick={() => {
                      setActiveChart("CS");
                    }}
                  >
                    CandleStick
                  </span>
                  <span
                    className={
                      "div-pill mx-2 p-1 general-nmphc-des col text-center" +
                      (activeChart == "TA" ? " our-border" : "")
                    }
                    onClick={() => {
                      setActiveChart("TA");
                    }}
                  >
                    Technical Analysis
                  </span>
                  <span
                    className={
                      "div-pill mx-2 p-1 general-nmphc-des col text-center" +
                      (activeChart == "D" ? " our-border" : "")
                    }
                    onClick={() => {
                      setActiveChart("D");
                    }}
                  >
                    Depth
                  </span>
                  <span
                    className={
                      "div-pill mx-2 p-1 general-nmphc-des col text-center" +
                      (activeChart == "FR" ? " our-border" : "")
                    }
                    onClick={() => {
                      setActiveChart("FR");
                    }}
                  >
                    Funding Rate
                  </span>
                </div>
                <div className="pt-3">
                  {activeChart == "CS" ? (
                    <HCCandleStickChart symbol={selectedIndex.symbol} />
                  ) : activeChart == "TA" ? (
                    <>TA</>
                  ) : activeChart == "D" ? (
                    <>D</>
                  ) : activeChart == "FR" ? (
                    <>FR</>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
