import React, { useEffect } from "react";
import { HomePage } from "../views/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import "../static/css/index.css";
import Signup from "./authentications/Signup";
import { FuturesPage } from "./FuturesPage";
import { NSE } from "./NSE";
import { NSEDocs } from "./nsedocs";

export default function AppRoutes() {
  const theme = useSelector((state) => state.theme);
  document.documentElement.setAttribute("data-theme", theme || "light");
  
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/futures" element={<FuturesPage />} exact />
        <Route path="/nse" element={<NSE />} exact />
        <Route path="/signup" element={<Signup />} exact />
        <Route path="/nse-docs" element={<NSEDocs />} exact />
        <Route path="*" element={<h1>WOW</h1>} />
        {/* <Route path="/user/*" element={<AdminLayout />} />
          <Route path="/login" element={<LoginButton />} />
          <Route path="/logout" element={<LogoutButton />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="*" element={<Navigate to="/user/dashboard" replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
