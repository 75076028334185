import React, { Component, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { exchangeTo } from "../utils/glob-redux/store";
function Navbar() {
  const exchanges = ["NSE", "INDIA", "GLOBAL", "COMBINED"];

  // const
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const exchange = useSelector((state) => state.exchange);
  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className="navbar general-nmphc-des">
      <div className="sitename">
        <a href="#">
          <i className="fa-solid fa-dice"></i>
        </a>
        <h2>Quibblefrost</h2>
      </div>
      <div className="sitemenu">
        <ul>
          {exchange === "NSE" ? (
            <>
              <li>
                <a href="/nse-docs">NSE Docs</a>
              </li>
              <li>
                <Link to="/nse">NSE</Link>
              </li>
              <li>
                <a href="#">Analytics</a>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <a href="/futures">Futures</a>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="accountmenu">
        <ul>
          <li>
            <i
              className={
                theme === "light" ? "fa-solid fa-moon" : "fa-solid fa-sun"
              }
              onClick={() => dispatch({ type: "SWITCH_THEME" })}
              style={{
                cursor: "pointer",
                transition: "2s",
                marginTop: "10px",
              }}
            ></i>
          </li>
          <li>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                caret
                size="sm"
                color="transparent"
                className="general-nmphc-des text-color-themed"
              >
                {exchange}
              </DropdownToggle>
              <DropdownMenu className="general-nmphc-des non-trans-bg">
                {exchanges.map((exchange, index) => (
                  <DropdownItem
                    key={index}
                    className="bg-set-on-active text-color-themed"
                    onClick={() => dispatch(exchangeTo(exchange))}
                  >
                    {exchange}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
