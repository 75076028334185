import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { commonApiGet } from "../utils/api";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import SparklineChartNSE from "../components/NSEComponents/SparklineChartNSE";
import Heatmap from "../components/NSEComponents/Heatmap";
import MarketOverview from "../components/NSEComponents/MarketOverview";
export const NSE = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [NSE, setNSE] = useState([]);
  const [stockDet, setStockDet] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("NIFTY 50");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shortSellingData, setShortSellingData] = useState([]);
  const [fiiDiiData, setFiiDiiData] = useState([]);
  const [gainerLoser, setGainerLoser] = useState({});
  const [tradingHoliday, setTradingHoliday] = useState([]);
  const [clearingHoliday, setClearingHoliday] = useState([]);

  useEffect(() => {
    (async () => {
      await commonApiGet("/market/short_selling")
        .then((res) => {
          setShortSellingData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    // /market/fii_dii
    (async () => {
      await commonApiGet("/market/fii_dii")
        .then((res) => {
          setFiiDiiData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    (async () => {
      await commonApiGet("/utils/holidays?type=trading")
        .then((res) => {
          setTradingHoliday(res.data.CM);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    (async () => {
      await commonApiGet("/utils/holidays?type=clearing")
        .then((res) => {
          setClearingHoliday(res.data.CM);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    (async () => {
      await commonApiGet("/market/gainers_losers")
        .then((res) => {
          setGainerLoser(res.data);
          console.log(gainerLoser, "setGainerLoser");
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const initCaller = async () => {
    try {
      const res = await commonApiGet("/indices/list");
      setNSE(Object.values(res.data));
      setSelectedIndex(res.data[0]);
      console.log(res.data, "hello");
    } catch (err) {
      console.log(err);
    }
  };
  const hasBeenCalled = useRef(false);

  useEffect(() => {
    if (!hasBeenCalled.current) {
      initCaller();
      hasBeenCalled.current = true;
    }
  }, []);

  useEffect(() => {
    console.log(selectedIndex, "selectedIndex");
    const apiCall = async () => {
      try {
        const indexDet1Day = await commonApiGet(
          `/indices/quote_ltp?symbol=${selectedIndex}`
        );
        console.log(indexDet1Day.data, "indexDet1Day");
        setStockDet(indexDet1Day.data);
      } catch (err) {
        console.log(err);
      }
    };
    apiCall();
  }, [selectedIndex]);

  return (
    <div className="container-fluid">
      <Navbar />

      <div className="main-content">
        <div className="row ">
          <div
            className="futures-card general-nmphc-in-chart-i-des col-12"
            style={{
              overflow: "unset",
            }}
          >
            <div className="row g-3">
              <div className="col-12">
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    caret
                    size="sm"
                    color="transparent"
                    className="general-nmphc-des text-color-themed"
                  >
                    {selectedIndex}
                  </DropdownToggle>
                  <DropdownMenu
                    className="general-nmphc-des non-trans-bg"
                    style={{
                      maxHeight: "220px",
                      overflowY: "scroll",
                    }}
                  >
                    <Input
                      type="search"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) =>
                        setSearchTerm(e.target.value.toUpperCase())
                      }
                    />
                    {console.log(NSE, "2314444")}
                    {NSE.filter((val) => val.includes(searchTerm)).map(
                      (val, key) => {
                        return (
                          <DropdownItem
                            className="bg-set-on-active text-color-themed"
                            key={`futures_${val || "symbol"}_${key}`}
                            onClick={() => {
                              setSelectedIndex(val);
                            }}
                          >
                            {val}
                          </DropdownItem>
                        );
                      }
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <br />
              <br />
              <hr />
              <br />
              <div className="col-md-4">
                <SparklineChartNSE sparklinesym={selectedIndex} />
              </div>
              <div className="col-md-4">
                <Heatmap symbol={selectedIndex} />
              </div>
              <hr />
              <div className="col-12 mt-0">
                <MarketOverview
                  shortSellingData={shortSellingData}
                  fiiDiiData={fiiDiiData}
                  tradingHoliday={tradingHoliday}
                  clearingHoliday={clearingHoliday}
                  gainerLoser={gainerLoser}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
