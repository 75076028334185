import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { chartColor } from "../../utils/sockets";
import { Link } from "react-router-dom";
import Forecast from "./ChartComponents/Forecast";
import { searchTerm } from "../../utils/functions";

const ResearchStocks = () => {
  const [researchStocksData, setResearchStocksData] = useState([]);
  const [selectedRes, setSelectedRes] = useState(0);
  const [dataToShowCurrPrev, setDataToShowCurrPrev] = useState([]);
  const holdValsObj = {
    B: "Buy",
    S: "Sell",
    H: "Hold",
    N: "Hold",
  };
  useEffect(() => {
    (async () => {
      await commonApiGet(`/analysis/research_stocks`)
        .then((res) => {
          setResearchStocksData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, []);

  useEffect(() => {
    if (researchStocksData.length > 0) {
      let preData = researchStocksData[selectedRes].stock_data.previous;
      let currData = researchStocksData[selectedRes].stock_data.current;
      console.log(preData, "prev");
      if (Array.isArray(preData))
        if (preData.length > 0) {
          setDataToShowCurrPrev([currData, preData]);
        } else {
          setDataToShowCurrPrev([currData]);
        }
      else {
        setDataToShowCurrPrev([currData, preData]);
      }
    }
  }, [researchStocksData, selectedRes]);

  console.log(
    researchStocksData,
    "jaisdn",
    dataToShowCurrPrev.length,
    dataToShowCurrPrev,
    "jaish-e-mohmmad"
  );

  return (
    <div className="general-nmphc-des p-2">
      <div className="general-nmphc-in-chart-i-des row m-0">
        {researchStocksData.length === 0 ? (
          <></>
        ) : (
          <div className="pt-2">
            <div className="row m-0">
              <div className="col-2">
                <i
                  className="div-pill general-nmphc-des our-border fas fa-caret-left"
                  onClick={() => {
                    setSelectedRes((prev) => {
                      if (0 === prev) {
                        return researchStocksData.length - 1;
                      }
                      return prev - 1;
                    });
                  }}
                ></i>
              </div>
              <div className="text-center col-8">
                <h6>{researchStocksData[selectedRes].stkname}</h6>
              </div>
              <div className="col-2">
                <i
                  className="div-pill general-nmphc-des our-border fas fa-caret-right"
                  onClick={() => {
                    setSelectedRes((prev) => {
                      if (researchStocksData.length - 1 === prev) {
                        return 0;
                      }
                      return prev + 1;
                    });
                  }}
                ></i>
              </div>
            </div>

            <hr />

            <center>{researchStocksData[selectedRes].heading}</center>
            <div className="general-nmphc-des p-2 mt-2 mb-2">
              <div className="px-1">
                <table className="table-td-r w-100">
                  <tbody>
                    <tr>
                      <th>Recommend Price:</th>
                      <td>
                        ₹{researchStocksData[selectedRes].recommended_price}
                      </td>
                    </tr>
                    <tr>
                      <th>Target Price:</th>
                      <td>₹{researchStocksData[selectedRes].target_price}</td>
                    </tr>
                    <tr>
                      <th>Current Price:</th>
                      <td>₹{researchStocksData[selectedRes].cmp}</td>
                    </tr>
                    <tr>
                      <th>Current Returns:</th>
                      <td>
                        {researchStocksData[selectedRes].current_returns}%
                      </td>
                    </tr>
                    <tr>
                      <th>Potential Returns:</th>
                      <td>
                        {researchStocksData[selectedRes].potential_returns_per}%
                      </td>
                    </tr>
                    <tr>
                      <th>Entry Date:</th>
                      <td>{researchStocksData[selectedRes].entry_date}</td>
                    </tr>
                    <tr>
                      <th>Reccommend Date:</th>
                      <td>
                        {researchStocksData[selectedRes].target_price_date}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <center>
                  <table
                    className="tab-border my-2"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>RCMD</th>
                        <th>TGT</th>
                        <th>TGT DT</th>
                        <th>ORG</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataToShowCurrPrev.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{holdValsObj[val.recommend_flag]}</td>
                            <td>{val.target_price}</td>
                            <td>{val.target_price_date}</td>
                            <td>{val.P_ORGANIZATION}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </center>
              </div>

              <div>
                <Forecast
                  searchTermProp={researchStocksData[selectedRes].stkname}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <span
                    className="div-pill general-nmphc-des our-border"
                    style={{
                      backgroundColor:
                        researchStocksData[selectedRes].recommend_flag === "BUY"
                          ? chartColor.green
                          : researchStocksData[selectedRes].recommend_flag ===
                            "SELL"
                          ? chartColor.red
                          : chartColor.yellow,
                      color: "#fff",
                    }}
                  >
                    {researchStocksData[selectedRes].recommend_flag}
                  </span>
                </div>
                <div
                  className="col-6"
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Link
                    to={researchStocksData[selectedRes].attachment}
                    target="_blank"
                    style={{
                      color: "var(--text-color)",
                      textDecoration: "none",
                    }}
                  >
                    <i className="fas fa-file"></i> Attachment
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResearchStocks;
