import React, { useState } from "react";
import OHLCChart from "./OHLCChart";

const OhlcuclcComp = ({
  open = 0,
  high = 0,
  low = 0,
  close = 0,
  uc = 0,
  lc = 0,
  combined = false,
}) => {
  const [exchangeForUCLCIN, setExchangeForUCLCIN] = useState(true);

  return (
    <div className="general-nmphc-in-chart-i-des p-2 font-small no-gapup">
      <div className="row">
        <div className="col-4">
          <center>
            <OHLCChart ohlc={[[open, high, low, close]]} />
          </center>
        </div>
        <div className="col-8 vert-center">
          <table className="th-right">
            <tbody>
              <tr>
                <th>Open: </th>
                <td>${open}</td>
              </tr>
              <tr>
                <th>Close: </th>
                <td>${close}</td>
              </tr>
              <tr>
                <th>High: </th>
                <td>${high}</td>
              </tr>
              <tr>
                <th>Low: </th>
                <td>${low}</td>
              </tr>
              {combined ? (
                <React.Fragment>
                  {exchangeForUCLCIN ? (
                    <>
                      <tr>
                        <th>UC:</th>
                        <td>${parseFloat(uc.in || 0).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>LC:</th>
                        <td>${parseFloat(lc.in || 0).toFixed(2)}</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <th>UC:</th>
                        <td>${parseFloat(uc.gl || 0).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>LC:</th>
                        <td>${parseFloat(lc.gl || 0).toFixed(2)}</td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        position: "relative",
                        paddingTop: "10px",
                      }}
                    >
                      <center>
                        <i
                          className="general-nmphc-des div-pill-left fas fa-caret-left"
                          onClick={() =>
                            setExchangeForUCLCIN(!exchangeForUCLCIN)
                          }
                        ></i>
                        <i
                          className="general-nmphc-des div-pill-right fas fa-caret-right"
                          onClick={() =>
                            setExchangeForUCLCIN(!exchangeForUCLCIN)
                          }
                        ></i>
                        <span>{exchangeForUCLCIN ? "INDIA" : "GLOBAL"}</span>
                      </center>
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <tr>
                    <th>UC: </th>
                    <td>${parseFloat(uc).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>LC: </th>
                    <td>${parseFloat(lc).toFixed(2)}</td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OhlcuclcComp;
