import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { commonApiGet, commonApiPost } from "../../utils/api";
import { Col, Row } from "reactstrap";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [verificationToken, setVerificationToken] = useState("error");
  const [verificationProcess, setVerificationProcess] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const [viewPass, setViewPass] = useState(false);

  const n = useNavigate();
  const handleSignUp = async () => {
    let extras = {};
    if (verificationToken !== "error") {
      extras = {
        verification_token: verificationToken,
        verification_code: otp.join(""),
      };
    }
    await commonApiPost("/signup", {
      email,
      first_name: firstName,
      last_name: lastName,
      username,
      password_confirm: passwordConfirm,
      password: password,
      mobile_number: mobileNumber,
      ...extras,
    })
      .then((res) => {
        console.log(res);
        setVerificationToken(res.data.verification_token || "error");
        if (res.status === 201) {
          n("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (verificationToken !== "error") {
      setVerificationProcess(true);
    }
  }, [verificationToken]);

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("Text");
    const pastedOtp = pastedData.match(/\d/g);
    if (pastedOtp && pastedOtp.length === 6) {
      setOtp(pastedOtp);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index >= 0 && otp[index] === "") {
      event.preventDefault();
      if (index == 0) index = 1;
      refs[index - 1].current.focus();
    } else {
      const newOtp = [...otp];
      const inputValue = event.key;
      // Accept only numerical values
      const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
      newOtp[index] = numericValue;
      setOtp(newOtp);
      if (index < 5 && numericValue !== "") {
        refs[index + 1].current.focus();
      }
    }
  };

  useEffect(() => {
    if (otp.join("").length === 6) {
      handleSignUp();
    }
  }, [otp]);

  return (
    <div className="container-fluid">
      <Navbar />
      <div
        className="centered-inside"
        style={{
          minHeight: "80%",
        }}
      >
        <div className="signup-container">
          <h3>{!verificationProcess ? "Sign up" : "Verify your mobile"}</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSignUp();
            }}
          >
            {verificationProcess == false ? (
              <Row>
                <Col sm={6}>
                  <input
                    type="text"
                    name="fname"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    required
                  />
                </Col>
                <Col sm={6}>
                  <input
                    type="text"
                    name="lname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                    required
                  />
                </Col>
                <Col sm={12}>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                  />
                </Col>
                <Col sm={5}>
                  <input
                    type="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                  />
                </Col>
                <Col sm={7}>
                  <input
                    type="tel"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                  />
                </Col>
                <Col
                  sm={6}
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    type={viewPass ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="password-toggle-icon">
                    <i
                      onClick={() => setViewPass(!viewPass)}
                      className={viewPass ? "fas fa-lock-open" : "fas fa-lock"}
                    ></i>
                  </span>
                </Col>
                <Col sm={6}>
                  <input
                    type={viewPass ? "text" : "password"}
                    name="passwordConfirm"
                    placeholder="Confirm Password"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    required
                  />
                </Col>
              </Row>
            ) : (
              <Row className="otp-verification-text-center">
                {otp.map((value, index) => (
                  <Col key={index}>
                    <input
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => e.preventDefault()}
                      onPaste={handlePaste}
                      ref={refs[index]}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                    />
                  </Col>
                ))}
              </Row>
            )}

            {!verificationProcess ? (
              <button type="submit">Sign up</button>
            ) : (
              <button type="button">Resend OTP</button>
            )}
          </form>
          {!verificationProcess ? (
            <>
              <br />
              <p>or Signup with Socials</p>
              <div className="social-signup">
                <span className="social-button fab fa-google"></span>
                <span href="#" className="social-button fab fa-facebook"></span>
                <span href="#" className="social-button fab fa-apple"></span>
                <span href="#" className="social-button fab fa-twitter"></span>
              </div>
              <p>
                Already have account? <Link to="/login">Login</Link>
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
