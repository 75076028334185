import { createStore } from "redux";

const initialState = {
  exchange: localStorage.getItem("exchange") || "NSE", // GLOBAL, INDIA, COMBINED, NSE
  theme: localStorage.getItem("theme") || "light", // light, dark
};

const handleStore = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EXCHANGE":
      let exchange = action.to
      localStorage.setItem("exchange", exchange)
      return { ...state, exchange };
    case "SWITCH_THEME":
      let theme = (state.theme === "light" ? "dark" : "light")
      localStorage.setItem("theme", theme)
      return { ...state, theme }; // toggle theme
    default:
      return state;
  }
};

const store = createStore(handleStore);
export default store;

export const exchangeTo = (exchangeName = "INDIA") => ({
  type: "SET_EXCHANGE",
  to: exchangeName,
});
