const socketFeedIndia = "wss://socket.india.delta.exchange";
const socketFeedGlobal = "wss://socket.delta.exchange";

export const nseBase = "https://api.quibblefrost.in"
export const nseBaseDocs = nseBase + "/docs"

const apiForFeedIndia = "https://api.india.delta.exchange/v2/tickers/";
const apiForFeedGlobal = "https://api.delta.exchange/v2/tickers/";

export const sparklineApi =
  "https://api.india.delta.exchange/v2/history/sparklines";

export const historicOHLCCandlesApi =
  "https://api.delta.exchange/v2/history/candles";

export const indiaAssetsApi = "https://api.india.delta.exchange/v2/assets"
export const indiaIndicesApi = "https://api.india.delta.exchange/v2/indices"

export const indiaProductApi = "https://api.india.delta.exchange/v2/products"


export const socketFieldForMultiExchange = {
  INDIA: socketFeedIndia,
  GLOBAL: socketFeedGlobal,
  COMBINED: {
    INDIA: socketFeedIndia,
    GLOBAL: socketFeedGlobal,
  },
};

export const apiFieldForMultiExchangeDefault = {
  INDIA: apiForFeedIndia,
  GLOBAL: apiForFeedGlobal,
  COMBINED: {
    INDIA: apiForFeedIndia,
    GLOBAL: apiForFeedGlobal,
  },
};

export const tickerSymsExchange = {
  BTCUSD: {
    INDIA: "BTCUSD",
    GLOBAL: "BTCUSDT",
  },
  ETHUSD: {
    INDIA: "ETHUSD",
    GLOBAL: "ETHUSDT",
  },
};

export const chartColor = {
  green: "#658d6d",
  red: "#b22222",
  yellow: "#fca103",
  opacGreen: "#658d6d69",
  opacRed: "#b2222269",
  opacYellow: "#fca10369",
};

export const toFetchCandlesSmall = 24;
export const toFetchCandlesBig = 500;

export const candleCountAccordingTimeframes = {
  "resolutionsAvailable":{
    miniChart: ['15m','1h','1d','7d','30d'],
    fullChart: ['15m','1h','1d','7d','30d'],
  },
  "15m": {
    miniChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 15 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesBig * 15 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "1h": {
    miniChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "1d": {
    miniChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 24 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 24 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "7d": {
    miniChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 24 * 7 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 24 * 7 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "30d": {
    miniChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 24 * 30 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 24 * 30 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
};

function formatDate(date, format) {
  const year = date.getFullYear();
  const month = getMonthName(date.getMonth());
  const day = date.getDate().toString().padStart(2, '0');
  return format.replace('DD', day).replace('MMM', month).replace('YY', year);
}

function getMonthName(month) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[month];
}
export const date4IndexHis = {
  current: formatDate(new Date(), 'DD-MMM-YY'),
  '1M': formatDate(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), 'DD-MMM-YY'),
  '2M': formatDate(new Date(new Date().getTime() - 60 * 24 * 60 * 60 * 1000), 'DD-MMM-YY'),
  '3M': formatDate(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), 'DD-MMM-YY'),
  '6M': formatDate(new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000), 'DD-MMM-YY'),
  '1Y': formatDate(new Date(new Date().getTime() - 360 * 24 * 60 * 60 * 1000), 'DD-MMM-YY'),
  options: ['1M','2M','3M','6M','1Y']
}

export function convertToDateNSE(unformattedDate) {
  if (unformattedDate === undefined) return
  console.log(unformattedDate)
  const [day, month, year] = unformattedDate.split('-');
  console.log(day,month,year);
  const months = {
    JAN: '01',
    FEB: '02',
    MAR: '03',
    APR: '04',
    MAY: '05',
    JUN: '06',
    JUL: '07',
    AUG: '08',
    SEP: '09',
    OCT: '10',
    NOV: '11',
    DEC: '12'
  };
  const formattedDate = `${year}-${months[month]}-${day}T00:00:00.000Z`;
  const date = new Date(formattedDate);
  const unixTimestampMs = date.getTime();
  console.log(unixTimestampMs);
  return unixTimestampMs;
}