import React from "react";
import Navbar from "../components/Navbar";
import { HomePageCryptoCard } from "../components/HomePageCryptoCard";
import { useSelector } from "react-redux";
import { HomePageCryptoCardCombined } from "../components/HomePageCryptoCardCombined";

export const HomePage = () => {
  const exchange = useSelector((state) => state.exchange);
  if(exchange === 'NSE'){
    location.replace('/nse')
  }
  const toBeShow_Crypto = [
    {
      tickerSymbol: "BTCUSD",
      spotSymbol: ".DEXBTUSDT",
      sparklinesym: "BTCUSD",
    },

    {
      tickerSymbol: "ETHUSD",
      spotSymbol: ".DEETHUSDT",
      sparklinesym: "ETHUSD",
    },
  ];

  return (
    <div className="container-fluid">
      <Navbar />

      <div className="main-content">
        {exchange !== "COMBINED"
          ? toBeShow_Crypto.map((item, key) => (
              <div className="card-wrapper" key={"HomePageCryptoCardKey" + key}>
                <HomePageCryptoCard
                  cryptoInfo={{}}
                  forTicker={item.tickerSymbol}
                  forSpot={item.spotSymbol}
                  sparklinesym={item.sparklinesym}
                />
              </div>
            ))
          : toBeShow_Crypto.map((item, key) => (
              <div className="card-wrapper" key={"HomePageCryptoCardKey" + key}>
                <HomePageCryptoCardCombined
                  cryptoInfo={{}}
                  forTicker={item.tickerSymbol}
                  forSpot={item.spotSymbol}
                  sparklinesym={item.sparklinesym}
                />
              </div>
            ))}
      </div>
    </div>
  );
};
