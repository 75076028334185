import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
// import Highcharts from "highcharts/highstock";
import Highcharts from "highcharts";
// import HighchartsReact from 'highcharts-react-official';
import HighchartsTreemap from "highcharts/modules/treemap";
import { chartColor } from "../../utils/sockets";
HighchartsTreemap(Highcharts);

function Heatmap({ symbol }) {
  const [heatmapData, setHeatmapData] = useState([]);
  const [displayCount, setDisplayCount] = useState(10); // Initial display count set to 10
  const [options, setOptions] = useState({});

  useEffect(() => {
    (async () => {
      await commonApiGet(`/indices/heatmap/${symbol.replace('/','%2F')}`)
        .then((res) => {
          const sortedData = res.data.sort(
            (a, b) => b.iislPercChange - a.iislPercChange
          );
          setHeatmapData(sortedData);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, [symbol]);

  const loadMoreItems = () => {
    setDisplayCount(displayCount + 10); // Increment display count by 10
  };

  return (
    <>
      <div className="general-nmphc-des p-2">
        <div
          className="general-nmphc-in-chart-i-des row m-0"
          style={{
            maxHeight: "310px",
            overflowY: "scroll",
            "--bs-gutter-x": "1rem",
            "--bs-gutter-y": "1rem",
            padding: "0 10px 20px 10px",
          }}
        >
          {heatmapData.slice(0, displayCount).map((val, key) => (
            <div key={key} className="col-6">
              <div
                className="heatmap"
                style={{
                  position: "relative",
                  background:
                    val.iislPercChange > 0
                      ? chartColor.opacGreen
                      : chartColor.opacRed,
                }}
              >
                <div className="heatmap-text">{key + 1}</div>
                <div className="row">
                  <div className="col-6">{val.symbol}</div>
                  <div
                    className="col-6"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    ₹{val.ltP}
                  </div>
                  <div className="col-6">{val.iislPercChange}%</div>
                  <div
                    className="col-6"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    ₹{val.iislPtsChange}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {displayCount < heatmapData.length && (
            <div onClick={loadMoreItems} className="load-more-button div-pill">
              <center>Load More</center>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Heatmap;
